import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/Seo"
import Layout from "../components/layout/Layout"
import HeaderPlain from "../components/HeaderPlain"
import Byline from "../components/Byline"
import GetUpdates from "../components/GetUpdates"

const ReflectionsPage = ({ data }) => {
  return (
    <>
      <Seo title="Reflections" bodyClass="page__reflections presentation" />
      <Layout>
        <section className="layout__main s__white reflections">
          <div className="nav__spacer"></div>
          <HeaderPlain
            title="Reflections"
            subtitle="Thoughts from our staff, partners, and fellows"
          />
          <div className="u__container u__vspacing">
            <div className="row u__spacing team py-0">
              <div className="col-sm-9 col-lg-8">
              {data.allContentfulReflection.nodes.map((node, index) => {
                return (
                  <div className="reflections__post" key={index}>
                    <h3 className="reflections__post__title h4">
                      <Link to={`/reflections/${node.slug}`}>{node.title}</Link>
                    </h3>
                    <p className="reflections__post__authors">
                      <Byline authors={node.authors} contentID={node.contentful_id} />
                    </p>
                    <p>{node.body.childMarkdownRemark.excerpt}</p>
                  </div>
                )
              })}
              </div>
            </div>
          </div>
        </section>
        <GetUpdates sectionClass="s__light" />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  {
    allContentfulReflection(sort: {fields: publishDate, order: DESC}) {
      nodes {
        title
        authors {
          contentful_id
          name
          slug
        }
        body {
          childMarkdownRemark {
            excerpt(format: PLAIN, pruneLength: 200)
          }
        }
        contentful_id
        slug
        publishDate
      }
    }
  }
`

export default ReflectionsPage
